// import GlobalSettings from '@/GlobalSettings'
import { IKDataEntity } from 'metaflow-js'
import hillo from 'hillo'
import GlobalSettings from '@/GlobalSettings'
import StringEntity from './StringEntity'

const entity = {
  id: {
    type: IKDataEntity.Types.Integer,
    displayName: 'id',
    tableConfig: {
      align: 'start',
    },
    formConfig: {
      requiredNew: false,
    },
  },
  langs: {
    displayName: 'consumeTypeStatusName',
    type: IKDataEntity.Types.Group,
    formConfig: {
      groupName: 'consumeTypeStatusName',
      default: GlobalSettings.supportLang,
      childLabelKey: 'lang',
      type: {},
    },
    tableConfig: {
      displayCondition: (item) => {
        return item.lang.toUpperCase() === GlobalSettings.getLang()
      },
    },
    childKey: 'name',
    labelKey: 'lang',
    children: [
      StringEntity, StringEntity, StringEntity,
    ],
  },
  name: {
    displayName: 'consumeTypeStatus',
  },
}
const config = {
  load: async function (filter) {
    return (await hillo.get('Orders.php', {
      op: 'showConsumeTypeStatusList',
      // lang: GlobalSettings.getLang(),
      ...filter,
    }))
      .content
  },
}

export default IKDataEntity.ModelFactory(entity, config)
