import GlobalSettings from '@/GlobalSettings'
import { IKDataEntity } from 'metaflow-js'
import hillo from 'hillo'
import Payment from '../../common/Payment'

const entity = {
  id: {
    type: IKDataEntity.Types.Integer,
    displayName: 'id',
    tableConfig: {
      align: 'start',
    },
    form: false,
  },
  paymentMethodId: {
    type: IKDataEntity.Types.Option,
    formConfig: {
      type: {
        selectItems: Payment.getList,
        itemText: '_langsname',
      },
    },
    displayName: 'payMethod',
  },
  createdAt: { form: false },
  memberCardId: {
    form: false,
  },
  amount: {
    form: false,
  },
}
const config = {
  load: async function (filter) {
    console.log(filter, (await hillo.get('BackendData.php', Object.assign({
      op: 'billDetail',
      lang: GlobalSettings.getLang(),
    }, ...filter)))
      .billPaymentInfo)
    return (await hillo.get('BackendData.php', Object.assign({
      op: 'billDetail',
      lang: GlobalSettings.getLang(),
    }, ...filter)))
      .billPaymentInfo
  },
}

export default IKDataEntity.ModelFactory(entity, config)
