<template>
  <v-container
    v-if="id!=='-1'"
    style="position: relative;height: 100vh;overflow-y: scroll"
  >
    <div
      class="d-flex align-center"
    >
      <slot name="navigation"/>
      <div class="text-h3 font-weight-bold">
        {{ $t('billDetail') }} · #{{ id }}
      </div>
      <v-spacer/>

      <v-btn
        v-if="billData.billInfo.paymentStatus==='1'"
        outlined
        style="background: white"
        @click="startChangePaymentMethodForOrder(order)"
      >
        {{ $t('changePayment') }}
      </v-btn>

      <v-btn
        class="ml-2"
        color="error darken-4"
        outlined
        style="background: white"
        @click="returnOrder"
      >
        {{ $t('billReturn') }}
      </v-btn>
      <v-btn
        class="ml-2"
        color="primary"
        elevation="0"
        @click="reprintDialogShow=true"
      >
        {{ $t('reprint') }}
      </v-btn>
    </div>
    <div
      class="d-flex"
      elevation="0"
    >
      <div class="flex-grow-1">
        <v-card
          class="pr-4 mt-8"
          outlined
        >
          <div
            class="text-h4 pa-4 pl-9"
          >
            <v-icon
              class="mr-2"
            >
              mdi-timeline
            </v-icon>

            {{ $t('mealsDetail') }}
          </div>
          <v-divider/>
          <v-timeline
            v-if="basicInfo"
            align-top
            dense
          >
            <v-timeline-item
              color="amber"
              small
            >
              <div class="d-flex pr-4">
                <div>{{ $t('startMeal') }}</div>
                <v-spacer/>
                {{ basicInfo.startTime | timeStampDisplay }}
              </div>
            </v-timeline-item>
            <template v-if="displayDetailInfo">
              <template v-for="t in Object.keys(displayDetailInfo)">
                <v-timeline-item
                  :key="t"
                  small
                >
                  <div>
                    <div
                      v-for="op in Object.keys(displayDetailInfo[t])"
                      :key="t+'op'+op"
                    >
                      <div class="d-flex pr-4">
                        <div> {{ $t(op) }}</div>
                        <v-spacer/>
                        {{ t | timeStampDisplay }}
                      </div>
                      <div class="mt-4">
                        <v-card
                          v-for="d in displayDetailInfo[t][op]"
                          :key="d.id"
                          class="pa-2 ml-0 ma-1 text-body-1"
                          color="#eee"
                          elevation="0"
                        >
                          <div>
                            <div class="d-flex">
                              <b>{{ Math.abs(d.count) }}&times; </b>
                              <span class="text-capitalize text-no-wrap text-truncate ml-2"> #{{
                                d.currentCode
                              }} </span>
                              <v-spacer/>
                              <template v-if="parseFloat(d.tempDiscountMod)!==0">
                                <span
                                  class="text-decoration-line-through warning--text text--darken-1"
                                  style="font-size: 10px"
                                >{{ d.price | priceDisplay }}</span>
                                <span class="text-no-wrap">
                                  {{ parseFloat(d.price) + parseFloat(d.tempDiscountMod) | priceDisplay }}</span>
                              </template>
                              <span
                                v-else
                                class="text-no-wrap"
                              >{{ d.price | priceDisplay }}</span>
                            </div>

                            <span class="maxLine2">{{ d.name }}</span>
                          </div>
                          <template v-if="d.aName">
                            <div class="text-caption">
                              {{ d.aName }}
                            </div>
                          </template>
                        </v-card>
                      </div>
                    </div>
                  </div>
                </v-timeline-item>
              </template>
            </template>
            <v-timeline-item
              v-if="billData.billInfo.paymentStatus === '1'"
              color="success"
              small
            >
              <div class="d-flex pr-4">
                <div>{{ $t('endMeal') }}</div>
                <v-spacer/>
                {{ basicInfo.endTime | timeStampDisplay }}
              </div>
            </v-timeline-item>
          </v-timeline>
        </v-card>

        <v-card
          class="pa-4 px-8 mt-6 font-weight-regular"
          elevation="0"
          outlined
        >
          <table
            class="mt-6"
            style="width: 100%"
          >
            <tbody>
              <tr>
                <td style="width: 140px">
                  {{ $t('subtotal') }}
                </td>
                <td style="width: 140px">
                  {{ $t('numberOfGoods', { numberOfGoods: count}) }}
                </td>
                <td style="text-align: right">
                  {{ dishTotalPrice | priceDisplay }}
                </td>
              </tr>
              <tr v-if="billData.billInfo.tipIncome>0">
                <td style="width: 140px">
                  {{ $t('tips') }}
                </td>
                <td style="width: 140px"/>
                <td style="text-align: right">
                  {{ billData.billInfo.tipIncome | priceDisplay }}
                </td>
              </tr>
              <tr v-if="billData.billInfo.discountStr">
                <td style="width: 140px">
                  {{ $t('tempDiscountStr') }}
                </td>
                <td style="width: 140px">
                  {{ $t('sumTo') }}
                </td>
                <td style="text-align: right">
                  {{ billData.billInfo.discountStr }}
                </td>
              </tr>
              <tr class="font-weight-bold">
                <td style="width: 140px">
                  {{ $t('amountTo') }}
                </td>
                <td/>

                <td style="text-align: right">
                  {{ totalPrice | priceDisplay }}
                </td>
              </tr>
            </tbody>
          </table>
          <v-divider class="my-4"/>
          <div class="text-subtitle-2 font-weight-regular">
            {{ $t('userPay') }}
          </div>
          <v-card
            class="mt-2"
            elevation="0"
          >
            <div
              v-for="item in paymentRecordList"
              :key="item.id"
              class="d-flex py-2"
              style="font-size: 16px "
            >
              <div
                class="primary mr-6 px-2 white--text d-flex align-center justify-center"
                style="border-radius: 8px"
              >
                {{ item.paymentMethodName }}
              </div>
              <v-spacer/>
              {{ item.amount | priceDisplay }}
            </div>
          </v-card>
        </v-card>
      </div>

      <div
        class="ml-8 mt-8 flex-shrink-0"
        style="position: sticky;top: 0;width: 300px"
      >
        <v-card
          class="py-4"
          elevation="0"
          outlined
        >
          <v-card
            elevation="0"
          >
            <div class="text-h4 px-4">
              {{ $t('normalInfo') }}
            </div>
            <div class="mt-8 px-4 text-body-1 font-weight-regular">
              <div
                class="text-h4"
              >
                <v-icon
                  v-if="billData.billInfo.paymentStatus==='1'"
                  class="mr-2"
                  color="success"
                >
                  mdi-check-decagram
                </v-icon>
                <v-icon
                  v-else
                  class="mr-2"
                  color="error"
                >
                  mdi-close-octagon-outline
                </v-icon>
                {{ paymentStatus }}
              </div>
            </div>
            <v-divider class="my-4"/>
            <div class="px-4">
              <div class="text-overline font-weight-bold">
                {{ $t('servantName') }} / {{ $t('tableName') }}
              </div>
              <div class="mt-2 text-body-1 font-weight-regular">
                {{ billData.billInfo.servantName }} / {{ billData.billInfo.tableName }}
              </div>
            </div>
            <div class="px-4 mt-4">
              <div class="text-overline font-weight-bold">
                {{ $t('number_of_diners') }}
              </div>
              <div class="mt-2 text-body-1 font-weight-regular">
                {{ billData.billInfo.personCount }}
              </div>
            </div>
            <div class="px-4 mt-4">
              <div class="text-overline font-weight-bold">
                {{ $t('meal_type') }}
              </div>
              <div class="mt-2 text-body-1 font-weight-regular">
                {{ consumeType }}
              </div>
            </div>
            <template v-if="rawAddressInfo">
              <v-divider class="my-4"/>
              <div class="px-4">
                <div class="text-overline">
                  {{ $t('address_info') }}
                </div>
                <div class="mt-2 text-body-1 font-weight-regular">
                  <address-info-block :address-info="rawAddressInfo"/>
                </div>
              </div>
            </template>
          </v-card>
        </v-card>
      </div>
    </div>
    <v-dialog
      v-model="printContentShow"
      width="480px"
    >
      <v-card
        class="pa-4"
        width="100%"
      >
        <i-k-p-r-node
          :node="parseNode(currentPrintContent)"
          level=""
          style="font-family: monospace"
        />
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="reprintDialogShow"
      max-width="600px"
    >
      <v-card class="pa-4">
        <div
          class="d-flex align-center"
        >
          <div class="text-h3 font-weight-bold">
            {{ $t('selectBillType') }}
          </div>
        </div>
        <div
          class="mt-4"
          style="display: grid;grid-template-columns: repeat(2,minmax(0,1fr));grid-gap: 24px"
        >
          <v-card
            class="pa-4 d-flex align-center justify-center"
            color="#eee"
            elevation="0"
            height="200"
            @click="reprintOrder(1)"
          >
            <div class="text-center">
              <v-icon size="64">
                mdi-text-box
              </v-icon>
              <div>{{ $t('normalBill') }}</div>
            </div>
          </v-card>
          <v-card
            class="pa-4 d-flex align-center justify-center"
            color="#eee"
            elevation="0"
            height="200"
            @click="reprintOrder(3)"
          >
            <div class="text-center">
              <v-icon size="64">
                mdi-text-box-outline
              </v-icon>
              <div>{{ $t('companyBill_X2') }}</div>
            </div>
          </v-card>
        </div>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="checkOutDialog"
      max-width="900px"
    >
      <v-card height="736px">
        <check-out-calculator
          :total="changeOrderTotal"
          style="height: 564px"
          @payment-cancel="checkOutDialog=false"
          @payment-submit="changePaymentMethod"
        />
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
  import { getDetailOrder, reprintOrder } from '@/common/Utlis/api'
  import i18n from '../../../../i18n'
  import AddressInfoBlock from '../../../../composable/base/AddressInfoBlock'
  import BillPaymentInfo from '../../../../model/bills/detail/BillPaymentInfo'
  import IKUtils from 'innerken-js-utils'
  import Payment from '../../../../model/common/Payment'
  import ConsumeTypeStatus from '../../../../model/common/ConsumeTypeStatus'
  import PrinterListByOrder, { getOneDishOrderRecords } from '../../../../model/bills/detail/PrinterListByOrder'
  import hillo from 'hillo'
  import ConsumeType from '../../../../model/buffet/ConsumeType'
  import { groupBy } from 'lodash'
  import IKPRNode from '@/composable/IKPRNode'
  import { parse } from 'node-html-parser'
  import { changePayMethodForOrder } from '@/common/Utlis/historyBill'
  import CheckOutCalculator from '@/views/AadenPages/components/GlobalDialog/CheckOutCalculator'
  import { showSuccessMessage } from '@/common/utils'

  export default {
    components: {
      IKPRNode,
      AddressInfoBlock,
      CheckOutCalculator,
    },
    props: {
      id: {
        default: '-1',
        type: [String, Number],
      },
      order: {
        default: () => {
          return { id: '-1' }
        },
        type: Object,
      },
    },
    data: function () {
      return {
        changeOrderId: null,
        changeOrderTotal: null,
        checkOutDialog: false,
        currentPrintContent: '',
        printContentShow: null,
        reprintDialogShow: null,
        BillPaymentInfo: BillPaymentInfo,
        loading: false,
        billData: {
          billInfo: { paymentStatus: 0 },
          billOrderInfo: [],
          billPaymentInfo: [],
        },
        payMethods: [],

        rawAddressInfo: '',
        infoList: [],
        orders: [],
        defaultItem: {
          paymentMethodId: -1,
          name: '',
        },
        consumeTypeStatusList: [],
        consumeType: '',
        consumeTypeStatus: '',
        paymentStatus: '',
        paymentRecordList: [],
        printRecordList: [],
        PrinterListByOrder: PrinterListByOrder,
      }
    },
    computed: {
      lineList () {
        const node = this.currentPrintContent.replace('<br>', '\n')
          .replace('<BR>', '\n').split('\n')
        console.log(node)
        return node
      },
      basicInfo () {
        return this.billData?.billInfo
      },
      dishTotalPrice () {
        return this.orders.reduce((sum, i) => sum + parseFloat(i.totalPrice), 0)
      },
      totalPrice () {
        return parseFloat(this.billData.billInfo.totalPrice ?? 0)
      },
      count () {
        return this.orders.reduce((sum, i) => sum + parseInt(i.sumCount), 0)
      },
      detailedOrders () {
        return (this.billData?.orderDetailInfo ?? []).map(o => {
          let name
          switch (o.dishStatus) {
            case '-1':
              name = 'returnDish'
              break
            case '-100':
              name = 'dividedOrder'
              break
            case '-101':
              name = 'tableChanged'
              break
            default:
              name = 'orderFood'
          }
          o.dishStatusName = name
          return o
        })
      },
      displayDetailInfo () {
        const groupedInfo = groupBy(this.detailedOrders, 'time')
        for (const time in groupedInfo) {
          groupedInfo[time] = groupBy(groupedInfo[time], 'dishStatusName')
          for (const key in groupedInfo[time]) {
            const timeInfo = groupBy(groupedInfo[time][key], (i) => {
              return i.currentCode + i.name + i.note + i.aName + i.tempDiscountStr
            })
            groupedInfo[time][key] = Object.keys(timeInfo).reduce((arr, i) => {
              arr.push(timeInfo[i].reduce((obj, t) => {
                obj.count = parseInt(obj.count) + parseInt(t.count)
                return obj
              }))
              return arr
            }, [])
          }
        }
        return groupedInfo
      },
    },
    watch: {
      id: {
        immediate: true,
        handler: function () {
          this.reloadItem(this.id)
        },
      },
    },

    methods: {
      startChangePaymentMethodForOrder (order) {
        this.changeOrderId = order.orderId
        this.changeOrderTotal = order.totalPrice
        this.checkOutDialog = true
      },
      async changePaymentMethod (paymentLog = []) {
        if (paymentLog?.length === 0) {
          paymentLog = [{
            id: 1,
            price: this.changeOrderTotal,
          }]
        }
        IKUtils.showLoading()
        const res = await changePayMethodForOrder(this.changeOrderId, paymentLog)
        console.log(res)
        showSuccessMessage('OK')
        this.checkOutDialog = false
        this.$emit('close-reload')
        // IKUtils.hideLoading()
      },
      parseNode (node) {
        return parse(node)
      },
      formatItem () {
        for (const i of this.payMethods) {
          i.name = this.findLangInArray(i.langs).name
        }
      },
      loadDetail: async function (id) {
        const res = await getDetailOrder(id)

        this.billData = res
        const list = await ConsumeType.getList()

        if (this.billData.billOrderInfo) {
          this.orders = this.billData.billOrderInfo.map((item, index) => ({
            id: index,
            consumeTypeName:
              item.overrideConsumeTypeId
                ? list.find(i => i.id === parseInt(item.overrideConsumeTypeId))?.name : '',
            ...item,
          }))
        } else {
          this.orders = this.billData.orderDetailInfo.map((item) => ({
            id: item.id,
            sumCount: item.count,
            code: item.currentCode,
            name: item.name,
            note: item.note,
            aName: item.aName,
            stamp: item.time,
            consumeTypeName: '',
            totalPrice: item.price,
          }))
        }
        this.rawAddressInfo = JSON.parse(this.billData.billInfo.rawAddressInfo)
        this.paymentStatus = this.billData.billInfo.paymentStatus === '1' ? this.$i18n.t('paid') : this.$i18n.t('not_paid')
        this.consumeTypeStatus = this.consumeTypeStatusList.find(i =>
          parseInt(this.billData.billInfo.consumeTypeStatusId) === i.id).name
        this.consumeType = list.find(i => parseInt(this.billData.billInfo.consumeTypeId) === i.id).name
        for (const item of this.payMethods) {
          if (parseInt(item.id) === parseInt(this.billData.billInfo.payMethod)) {
            this.defaultItem.name = item.name
          }
        }
        this.infoList = [
          {
            keyname: this.$i18n.t('orderNumber') + '/' + this.$i18n.t('tableName'),
            value: this.billData.billInfo.id + '/' + this.billData.billInfo.tableName,
          },
          {
            keyname: this.$i18n.t('servantName'),
            value: this.billData.billInfo.servantName,
          },
          {
            keyname: this.$i18n.t('startTime'),
            value: this.billData.billInfo.startTime,
          },
          {
            keyname: this.$i18n.t('checkout_time'),
            value: this.billData.billInfo.endTime,
          },
          {
            keyname: this.$i18n.t('tipIncome'),
            value: this.billData.billInfo.tipIncome,
          },
          {
            keyname: this.$i18n.t('discount'),
            value: this.billData.billInfo.discountStr,
          },
          {
            keyname: this.$i18n.t('totalPrice'),
            value: this.billData.billInfo.totalPrice,
          },
        ]
        this.paymentRecordList = res?.billPaymentInfo.map(item => {
          item.paymentMethodName = this.findPaymentName(item.paymentMethodId)
          item.createdAt = item.createdAt ?? ''
          return item
        }) ?? []
        this.printRecordList = await getOneDishOrderRecords(this.id)
      },
      findPaymentName (id) {
        return this.payMethods.find(it => parseInt(it.id) === parseInt(id)).name
      },
      async reloadItem (id) {
        this.consumeTypeStatusList = await ConsumeTypeStatus.getList()
        this.payMethods = (await Payment.getList())
        this.formatItem()
        this.loadDetail(id)
      },
      async returnOrder () {
        await IKUtils.showConfirm(this.$t('are_you_sure'), this.$t('You want to return this order') + '.', () => {
          this.returnOrderAPI(this.id)
          showSuccessMessage(this.$t('Erfolgreich'))
        })
      },
      async returnOrderAPI (id) {
        await hillo.post('Complex.php?op=returnOrder', { orderId: this.id })
      },

      async reprintOrder (value) {
        value = parseInt(value)
        let withTitle = 0
        let printCount = 1
        if (value > 1) {
          withTitle = 1
        }
        if (value > 2) {
          printCount = 2
        }
        await reprintOrder(this.id, withTitle, printCount)
        showSuccessMessage(this.$t('print_success'))
        this.reprintDialogShow = false
      },
      findLangInArray (langArray, target = i18n.locale) {
        return langArray.find((item) => {
          return item.lang.toLowerCase() === target.toLowerCase()
        })
      },
      async reprint (item) {
        await this.PrinterListByOrder.reprint(item)
        showSuccessMessage()
      },
    },
  }
</script>

<style scoped>
td {
  padding: 4px 0;
}
</style>
