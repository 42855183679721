import hillo from 'hillo'

export async function changePayMethodForOrder (orderId, paymentLogs) {
  // console.log(orderId, paymentLogs)
  return (await hillo.post('Complex.php?op=changePayMethodForOrder', {
    orderId: orderId,
    paymentLog: JSON.stringify(paymentLogs),
  }))
}

