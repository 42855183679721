import { IKDataEntity } from 'metaflow-js'
import hillo from 'hillo'
import PrinterGroup from '../../print/PrinterGroup'
import BonTypeNames from '../../print/BonTypeNames'
import PrintStatus from '../../print/PrintStatus'

const entity = {
  id: {
    type: IKDataEntity.Types.Integer,
    displayName: 'id',
    tableConfig: {
      align: 'start',
    },
  },
  printStatus: {
    displayName: 'printStatus',
    type: IKDataEntity.Types.Option,
    formConfig: {
      type: {
        selectItems: PrintStatus.getList(),
        itemText: 'name',
      },
    },
  },
  addTimeStamp: {
    displayName: 'addTimeStamp',
  },
  printBonClass: {
    type: IKDataEntity.Types.Option,
    formConfig: {
      type: {
        selectItems: BonTypeNames.getList(),
      },
    },
  },
  printerGroupId: {
    type: IKDataEntity.Types.Option,
    formConfig: {
      type: {
        selectItems: PrinterGroup.getList,
      },
    },
  },
  retryCount: {},
  printerMsg: {
    displayName: 'printerMsg',
  },
  content: {
    displayName: 'checkContent',
    header: false,
  },
}
const config = {
  load: async function (filter) {
    return await getOneDishOrderRecords(filter[0].id)
  },
  reprint: function (item) {
    return hillo.get('Printer.php?op=reprintWithDefault', { id: item.id })
  },
}

export async function getOneDishOrderRecords (id) {
  return (await hillo.get('PrintRecord.php', {
      op: 'getOneDishOrderRecords',
      id,
    })
  ).content
}

export default IKDataEntity.ModelFactory(entity, config)
